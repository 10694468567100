const blue = {
  50:   '#e1f1ff',
  100:  '#b8dcff',
  200:  '#86c7ff',
  300:  '#49b0ff',
  400:  '#009eff',
  500:  '#008cff',
  600:  '#007dff',
  700:  '#0c69ff',
  800:  '#1f56ec',
  900:  '#2e2fcc',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

export default blue;
