import React, { useState } from "react";

import Button               from "@material-ui/core/Button";
import CalendarEntry        from "./CalendarEntry";
import KeyboardArrowLeft    from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight   from "@material-ui/icons/KeyboardArrowRight";
import useMediaQuery        from "@material-ui/core/useMediaQuery";
import { bookingFirstDate } from "../../utils/calendar";
import { bookingLastDate }  from "../../utils/calendar";
import { makeStyles }       from "@material-ui/core/styles";
import { useTheme }         from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "5px",
  },
});

export default function WeekDatePicker(props) {
  const classes = useStyles();
  const theme   = useTheme();
  const matches = useMediaQuery(theme.breakpoints.only('xs'));
  const entries = matches ? 3 : 7;

  const [days, setDays]            = useState(0);
  const { onChange, selectedDate } = props;

  const calendarEntries = [...Array(entries)].map((_, index) => {
    const date     = bookingFirstDate().add(index + days, "days");
    const active   = date.isSame(selectedDate, "day");

    const disabled = date.isBefore(bookingFirstDate(), "day") ||
      date.isAfter(bookingLastDate(), "day");

    return (
      <CalendarEntry
        key={index}
        active={active}
        date={date}
        disabled={disabled}
        onClick={onChange}
      />
    )
  });

  return (
    <div className={classes.root}>
      <Button size="small" onClick={() => setDays(days - 1)}>
        <KeyboardArrowLeft />
      </Button>

      { calendarEntries }

      <Button size="small" onClick={() => setDays(days + 1)}>
        <KeyboardArrowRight />
      </Button>
    </div>
  );
}
