import React, { useState, useEffect } from "react";

import AddIcon              from "@material-ui/icons/Add";
import AliadaCard           from "../../components/AliadaCard/AliadaCard";
import CircularProgress     from "@material-ui/core/CircularProgress";
import Container            from "@material-ui/core/Container";
import Divider              from "@material-ui/core/Divider";
import Fab                  from "@material-ui/core/Fab";
import Hidden               from "@material-ui/core/Hidden";
import HourPicker           from "../../components/HourPicker";
import Typography           from "@material-ui/core/Typography";
import WeekDatePicker       from "../../components/WeekDatePicker";
import moment               from "moment";
import momentTimeZone       from "moment-timezone";
import qs                   from "../../utils/querystring";
import { Amplitude }        from '@amplitude/react-amplitude';
import { LogOnMount }       from '@amplitude/react-amplitude';
import { bookingFirstDate } from "../../utils/calendar";
import { fetchAliadas }     from "../../utils/api/Aliadas";
import { fetchCalendar }    from "../../utils/api/Calendar";
import { makeStyles }       from "@material-ui/core/styles";
import AliadaAutocomplete   from "../../components/AliadaAutocomplete";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import IconButton     from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { isUrgent }   from "../../utils/serviceType";
import UrgentService from "../../components/UrgentService";
import UrgentServiceOutOfTime from "../../components/UrgentServiceOutOfTime";
import Link from "@material-ui/core/Link";
import ReactGA from 'react-ga';
import { TIMEZONE,
         START_HOUR_SERVICES,
         END_HOUR_URGENT_SERVICES,
         END_HOUR_URGENT_SERVICES_SATURDAY,
         END_HOUR_URGENT_SERVICES_SUNDAY,
         ANALYTICS_UA } from "../../utils/constants.js"


ReactGA.initialize(ANALYTICS_UA, { testMode: process.env.NODE_ENV === 'test' });
ReactGA.pageview('onboarding/s/aliadas');

const useStyles = makeStyles(theme => ({
  root: {
  },
  loader: {
    display:        "flex",
    flexWrap:       "wrap",
    justifyContent: "space-evenly",
    marginTop:      "45px"
  },
  list: {
    display:        "flex",
    flexWrap:       "wrap",
    justifyContent: "space-evenly",
    marginTop:      "20px",
    marginBottom:   "10px",
    maxWidth:       "1200px",
  },
  noResults: {
    margin: "30px auto",
  },
  divider: {
    margin: "18px 0px 7px 0px",
  },
  weekDatePicker: {
    margin: "18px auto",
  },
  hourPicker: {
    margin: "9px auto",
  },
  container: {
    display:       "flex",
    flexDirection: "column",
    alignItems:    "center",

    [theme.breakpoints.up('sm')]: {
      alignItems: "flex-end",
    }
  },
  button: {
    marginBottom: "35px",

    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(12),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  AliadaAutocomplete: {
    margin: "auto",
    marginTop: "5%",
  },
  InfoBooking: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: '5%',
  },
  BookingForm: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px"
  },
  closeBookingForm: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    marginTop: '5%',
  },
  textCloseBookingForm: {
    fontWeight: "bold"
  },
  dataBookingForm: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    width: "100%"
    // alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "80%",
    marginLeft: 0
  },
  subtitle2: {
    color: "black",
    fontWeight: 700,
    marginRight: '5%',
  },
  closeIcon: {
    padding: '2px',
  },
  frecuency: {
    marginTop: "5%"
  },
  selections: {
    display: "grid",
    width: "100%",
    gridTemplateRows: "100%",
    gridTemplateColumns: "repeat(2, 50%)",
    justifyItems: "left"
  },
  skills: {
    marginTop: "2%",
    marginBottom: "5%"
  },
  skillChips: {
    display: "flex",
    justifyContent: "space-between"
  },
  fieldSet: {
  width: "100%"
  },
  RadioGroup: {
    justifyContent: "space-between"
  },
  label: {
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: '500',
    marginBottom:"7px"
  },
  selects:{
    height: "45px"
  },
  radios:{
    paddingTop: "2px"
  },
  editService:{
    textAlign:"center",
    display: "flex",
    alignItems: "flex-end",
    lineHeight: "1.3em"
  }
}));

const defaultTimeslots = [
  { "hour":  7, "available": false },
  { "hour":  8, "available": false },
  { "hour":  9, "available": false },
  { "hour": 10, "available": false },
  { "hour": 11, "available": false },
  { "hour": 12, "available": false },
  { "hour": 13, "available": false },
  { "hour": 14, "available": false },
  { "hour": 15, "available": false },
  { "hour": 16, "available": false },
  { "hour": 17, "available": false },
  { "hour": 18, "available": false },
];

export default function AvailableAliadasForBooking(props) {
  const classes = useStyles();

  function MoreResultsFab(props) {
    const { onClick } = props;

    return (
      <Amplitude>
        {({ instrument }) => (
          <Fab
            variant="extended"
            size="medium"
            className={classes.button}
            onClick={instrument('more-results-click', onClick)}
          >
            <AddIcon className={classes.icon} />
            Ver más
          </Fab>
        )}
      </Amplitude>
    );
  }

  const {
    neighborhood,
    latitude,
    longitude,
    serviceHours,
    serviceType,
    userId,
    skills,
    preferences,
  } = qs.decode(props.location.search);

  const [aliadas, setAliadas]           = useState([]);
  const [date, setDate]                 = useState(bookingFirstDate());
  const [hour, setHour]                 = useState(10);
  const [isFetching, setIsFetching]     = useState(true);
  const [availabilityFinderResponding, setAvailabilityFinderResponding] = useState(true);
  const [listLength, setListLength]     = useState(3);
  const [timeSlots, setTimeSlots]       = useState(defaultTimeslots)
  const [isUpdating, setIsUpdating]     = useState(false)
  const [serviceTypeState, setServiceTypeState] = useState(serviceType === undefined ? 'one-time' : serviceType)
  const [estimatedHoursState, setEstimatedHoursState] = useState(serviceHours === undefined ? 3 : serviceHours)
  const [pets, setPets] = useState(preferences === undefined ? false : preferences.includes('pets'))
  const [stateSkills, setStateSkills] = useState(skills === undefined ? ['cleaning'] : skills)
  const [urgentServiceOutOfTime, setUrgentServiceOutOfTime] = useState(false)

  const daysBeforeService = (() => {
    const diff     = date.diff(moment());
    const duration = moment.duration(diff);

    return Math.round(duration.asDays());
  })();

  useEffect(() => {
    async function fetch() {
      const {
        latitude,
        longitude,
        userId
      } = qs.decode(props.location.search);

      const params = {
        date:         date,
        latitude:     latitude,
        longitude:    longitude,
        preferences:  pets ? ['pets'] : undefined,
        serviceHours: estimatedHoursState,
        serviceType:  serviceTypeState,
        skills:       stateSkills,
        time:         hour,
        userId:       userId,
      };

      const aliadas_response  = await fetchAliadas(params);
      const calendar_response = await fetchCalendar(params);

      if(!aliadas_response || !calendar_response){
        setAvailabilityFinderResponding(false)
        setAliadas([])
        setTimeSlots(defaultTimeslots)
      }else{
        setAliadas(aliadas_response.results)
        setTimeSlots(calendar_response)
      }

      setIsFetching(false)
    }

    if(availabilityFinderResponding){
      setIsFetching(true);
      setListLength(3);
      fetch();
    }

    const nowDate  = new Date()
    const datetime = momentTimeZone.tz(nowDate, TIMEZONE)
    const day      = datetime.day()
    const nowHour  = datetime.hour()
    const saturday = 6
    const sunday   = 0

    setUrgentServiceOutOfTime(
      day === saturday ?
        !(START_HOUR_SERVICES <= nowHour && nowHour < END_HOUR_URGENT_SERVICES_SATURDAY ) :
        day === sunday   ?
          !(START_HOUR_SERVICES <= nowHour && nowHour < END_HOUR_URGENT_SERVICES_SUNDAY) :
          !(START_HOUR_SERVICES <= nowHour && nowHour < END_HOUR_URGENT_SERVICES))
  }, [hour, date, stateSkills, pets, serviceTypeState, estimatedHoursState, props.location.search,availabilityFinderResponding]);

  const list = aliadas.slice(0, listLength).map((aliada, index) => {
    const isLast = () => index === (listLength - 1);

    return (
      <div key={aliada.id}>
        <AliadaCard
          id={aliada.id}
          firstName={aliada.first_name}
          profilePicture={aliada.profile_picture.hdpi}
          serviceCost={aliada.service_cost}
          serviceOriginalCost={aliada.service_original_cost}
          superaliada={aliada.superaliada}
          averageRating={aliada.average_rating}
          finishedServices={aliada.finished_services_count}
          userRatings={aliada.ratings}
          date={date}
          hour={hour}
          serviceHours={estimatedHoursState}
          serviceType={serviceTypeState}
          userId={userId}
          index={index}
        />

        { !isLast() &&
          <Hidden smUp>
            <Divider light={true} className={classes.divider} />
          </Hidden>
        }
      </div>
    );
  });

  const properties = {
    daysBeforeService: daysBeforeService,
    time:              hour,
    weekday:           date.format('dddd'),
  };

  const handleOnShowMore = () => {
    const increment = Math.min(6, aliadas.length);

    setListLength(listLength + increment);
  }

  const handleServiceTypeChange = event => {
    setServiceTypeState(event.target.value)
  }

  const handleEstimatedHours = event => {
    setEstimatedHoursState(event.target.value)
  }

  const handleBookingForm = () => {
    setIsUpdating(!isUpdating)

    if(isUpdating){
      ReactGA.event({
        category: 'EDITAR SERVICIO',
        action: 'Editar parámetros de la búsqueda'
      })
    }
  }

  const handlePets = () => {
    setPets(!pets)
  }

  const deleteSkill = chipSkill => () => {
    setStateSkills(skills => skills.filter(skill => skill !== chipSkill));
  }

  const addSkill = chipSkill => () => {
    setStateSkills(skills => skills.includes(chipSkill.key) ? skills : [...skills, chipSkill]);
  }

  useEffect(() => {
    document.title = 'Aliada - Aliadas Disponibles'
  }, []);

  return (
    <div className={classes.root}>
      <Amplitude
        eventProperties={properties}
        userProperties={properties}
        debounceInterval={800}
      >
        <LogOnMount eventType="available-aliadas-screen" />

        <Container maxWidth='sm'>
          {!isUrgent(serviceTypeState) && (
            <div className={classes.AliadaAutocomplete}>
              <AliadaAutocomplete
                userId={userId}
                serviceHours={estimatedHoursState}
                serviceType={serviceTypeState}
                latitude={latitude}
                longitude={longitude}
              />
            </div>
          )}

          {!isUpdating && (
            <div className={classes.InfoBooking}>
              <Typography variant="subtitle2" align="center" className={classes.subtitle2}>
                {
                  serviceTypeState === 'one-time' ?
                  'Servicio Único. ' :
                  serviceTypeState === 'recurrent' ?
                  'Recurrencia. ' : 'Servicio Urgente. '
                }
                {(neighborhood !== undefined) && `${neighborhood}. `}
                {estimatedHoursState} horas
              </Typography>

              <Typography className={classes.editService}>
                <Link href="#" onClick={handleBookingForm}>
                  EDITAR SERVICIO
                </Link>
              </Typography>
            </div>
          )}

          {isUpdating &&
            <div className={classes.BookingForm}>
              <div className={classes.closeBookingForm}>
                <IconButton onClick={handleBookingForm} className={classes.closeIcon}>
                  <CloseIcon />
                </IconButton>
                <Typography variant="body2" className={classes.textCloseBookingForm}>
                  Editar Servicio
                </Typography>
              </div>

              <Divider />

              <div className={classes.dataBookingForm}>
                <div className={classes.frecuency}>
                  <FormControl component="fieldset" className={classes.fieldSet}>
                    <FormLabel component="legend" focused={false} className={classes.label}>FRECUENCIA DEL SERVICIO</FormLabel>
                    <RadioGroup aria-label="position" name="position"
                                onChange={handleServiceTypeChange} row className={classes.RadioGroup}>
                      <FormControlLabel
                        value="one-time"
                        control={<Radio color="primary" className={classes.radios} />}
                        label="Una vez"
                        labelPlacement="end"
                        checked={serviceTypeState === 'one-time'}
                      />
                      <FormControlLabel
                        value="recurrent"
                        control={<Radio color="primary" className={classes.radios}/>}
                        label="Recurrencia"
                        labelPlacement="end"
                        checked={serviceTypeState === 'recurrent'}
                      />
                      <FormControlLabel
                        value="urgent"
                        control={<Radio color="primary" className={classes.radios} />}
                        label="Urgente"
                        labelPlacement="end"
                        checked={serviceTypeState === 'urgent'}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className={classes.selections}>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Typography variant='subtitle1' className={classes.label}>
                      DURACIÓN
                    </Typography>
                    <Select
                      labelId="estimatedHours"
                      id="estimatedHours"
                      value={estimatedHoursState}
                      onChange={handleEstimatedHours}
                      className={classes.selects}
                    >
                      <MenuItem value={3}>3 Horas</MenuItem>
                      <MenuItem value={4}>4 Horas</MenuItem>
                      <MenuItem value={5}>5 Horas</MenuItem>
                      <MenuItem value={6}>6 Horas</MenuItem>
                      <MenuItem value={7}>7 Horas</MenuItem>
                      <MenuItem value={8}>8 Horas</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="outlined" className={classes.formControl}>
                    <Typography variant='subtitle1' className={classes.label}>
                      MASCOTAS
                    </Typography>
                    <Select
                      labelId="pets"
                      id="pets"
                      value={pets}
                      onChange={handlePets}
                      className={classes.selects}
                    >
                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Sí</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.skills}>
                  <Typography variant='subtitle1' className={classes.label}>
                    SERVICIOS ADICIONALES
                  </Typography>

                  <div className={classes.skillChips}>
                    <Chip
                      key="laundry"
                      label="Lavado"
                      onClick={addSkill('laundry')}
                      onDelete={deleteSkill('laundry')}
                      color={stateSkills.includes('laundry') ? 'primary': 'default'}
                    />
                    <Chip
                      key="ironing"
                      label="Planchado"
                      onClick={addSkill('ironing')}
                      onDelete={deleteSkill('ironing')}
                      color={stateSkills.includes('ironing') ? 'primary': 'default'}
                    />
                    <Chip
                      key="cooking"
                      label="Cocina"
                      onClick={addSkill('cooking')}
                      onDelete={deleteSkill('cooking')}
                      color={stateSkills.includes('cooking') ? 'primary': 'default'}
                    />
                  </div>
                </div>
              </div>
            </div>
          }

          <Divider />

          {isUrgent(serviceTypeState) && urgentServiceOutOfTime && (
            <UrgentServiceOutOfTime
              hours={estimatedHoursState}
              userId={userId}
            />
          )}

          {isUrgent(serviceTypeState) && !urgentServiceOutOfTime && (
            <UrgentService
              hours={estimatedHoursState}
              userId={userId}
            />
          )}

          {!isUrgent(serviceTypeState) && (
            <div>
              <Typography variant="h6" align="center">
                Elige a tu aliada
              </Typography>
              <Typography variant="body2" align="center">
                Elige la fecha y hora en la que deseas recibir a tu aliada. Te
                mostraremos a nuestras mejores aliadas disponibles.
              </Typography>

              <div className={classes.weekDatePicker}>
                <WeekDatePicker selectedDate={date} onChange={setDate} />
              </div>

              <div className={classes.hourPicker}>
                <HourPicker hour={hour} onChange={setHour} slots={timeSlots} />
              </div>
            </div>
          )}
        </Container>

        {!isUrgent(serviceTypeState) && (
          <div>
            { isFetching &&
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            }

            { !isFetching && aliadas.length !== 0 && (
              <div>
                <div className={classes.list}>
                  { list }
                </div>

                <div className={classes.container}>
                  { aliadas.length > listLength &&
                    <MoreResultsFab onClick={handleOnShowMore} />
                  }
                </div>
              </div>
            )}

            {
              !isFetching && aliadas.length === 0 && availabilityFinderResponding &&
              <Container maxWidth='sm' className={classes.noResults}>
                <Typography variant="body1" align="left" color="textSecondary">
                  Lo sentimos, no tenemos aliadas disponibles el
                  {date.format(" dddd D [de] MMMM")} a las {hour} horas.
                </Typography>

                <br />

                <Typography variant="body1" align="left" color="textSecondary">
                  Te sugerimos buscar en un horario distinto.
                </Typography>
              </Container>
            }

            {
              !availabilityFinderResponding &&
              <Container maxWidth='sm' className={classes.noResults}>
                <Typography variant="h6" align="center" color="default" size="medium">
                  Tenemos problemas
                </Typography>

                <Typography variant="h6" align="center" color="error" size="medium">
                  ¡No podemos encontrar disponibilidad!
                </Typography>
              </Container>
            }
          </div>
        )}
      </Amplitude>
    </div>
  );
};
