import React from "react";

import Avatar         from "@material-ui/core/Avatar";
import Typography     from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    alignItems:     "flex-start",
    display:        "flex",
    justifyContent: "space-evenly",
    margin:         "12px 0px",
    width:          "280px",
    height:         "90px",
  },
  column: {
    display:       "flex",
    flexDirection: "column",
    margin:        "8px 10px",
  },
  avatar: {
    borderRadius: "12%",
    width:         80,
    height:        80,
  },
  averageRating: {
    display:        "flex",
    alignItems:     "center",
    justifyContent: "flex-start",
  },
  overline: {
    lineHeight: 1.0,
  },
  text: {
    margin: "auto 0px",
  },
});

function SuperAliadaOverline() {
  const classes = useStyles();

  return (
    <Typography
      className={classes.overline}
      color="primary"
      variant="overline"
    >
      SUPERALIADA
    </Typography>
  );
}

function NewAliadaOverline() {
  const classes = useStyles();

  return (
    <Typography
      className={classes.overline}
      style={{color: '#FF8C42'}}
      variant="overline"
    >
      NUEVA ALIADA
    </Typography>
  );
}

export default function AliadaCardHeader(props) {
  const classes = useStyles();

  const {
    averageRating,
    finishedServices,
    firstName,
    newAliada,
    profilePicture,
    serviceCost,
    serviceOriginalCost,
    superaliada,
  } = props;

  function showPriceUp(){
    return serviceOriginalCost ? '$'+serviceOriginalCost : '$'+serviceCost
  }

  function showPriceDown(){
    return serviceOriginalCost ? '$' + serviceCost : null
  }

  return (
    <div className={classes.root}>
      <Avatar alt={firstName} src={profilePicture} className={classes.avatar}>
        <Typography variant="h5">
          {firstName.split("")[0]}
        </Typography>
      </Avatar>

      <div className={classes.column}>
        { superaliada && <SuperAliadaOverline /> }
        { newAliada   && <NewAliadaOverline /> }

    <Typography variant="h6" className={classes.text}>
          {firstName.split(" ")[0]}
        </Typography>

        <div className={classes.averageRating}>
          <Typography variant="caption" className={classes.text}>
            {averageRating.toFixed(1)} ★
          </Typography>
        </div>

        <Typography variant="caption" color='textSecondary' className={classes.text}>
          {finishedServices} servicios
        </Typography>
      </div>

      <div className={classes.column} styles={{alignSelf: 'flex-start', marginTop: '0px'}}>
        <Typography variant="h6"
                    className={classes.text}
                    color={serviceOriginalCost ? 'textSecondary' : 'default'}
                    style={serviceOriginalCost ? {fontSize:            17,
                                                  textDecorationLine:  'line-through',
                                                  textDecorationStyle: 'solid',
                                                  alignSelf:           'center'} : {}}
        >
          {showPriceUp()}
        </Typography>
        <Typography variant="h6" className={classes.text}>
          {showPriceDown()}
        </Typography>
      </div>
    </div>
  );
};
