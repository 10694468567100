import moment from 'moment';

const bookingHorizonDays = 28;

export function bookingFirstDate() {
  const hourLimit = moment('08:00 pm', 'HH:mm a');
  const daysToAdd = moment().isBefore(hourLimit) ? 1 : 2;

  const datetime = moment().hours(10).minutes(0).seconds(0);

  return datetime.add(daysToAdd, 'days');
};

export function bookingLastDate () {
  return moment().add(bookingHorizonDays, 'days');
};
