import React, { useState, useEffect } from "react";
import GoogleAutocomplete from "react-google-autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import PlaceIcon from "@material-ui/icons/Place";
import Aliada from "../assets/images/aliada.png";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import moment from "moment-timezone";
import Hidden from "@material-ui/core/Hidden";
import ReactGA from 'react-ga';

import { TIMEZONE, ANALYTICS_UA } from "../utils/constants";
import qs from "../utils/querystring";


ReactGA.initialize(ANALYTICS_UA, { testMode: process.env.NODE_ENV === 'test' });
ReactGA.pageview('onboarding/');

const useStyles = makeStyles({
  button: {
    display: "flex",
    width: "83%",
    marginTop: "5px",
    marginBottom: "5px"
  },
  RadioGroup: {
    justifyContent: "space-between"
  },
  radios: {
    paddingTop: "2px"
  },
  autocomplete: {
    display: "flex",
    direction: "row"
  },
  aliadaText: {
    fontFamily: "Raleway",
    fontSize: "24px",
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "rgba(0, 0, 0, 0.87)"
  },
  alert: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  skillChips: {
    display: "flex",
    justifyContent: "space-between",
    width: '60%',
  },
  mainDiv: {
    display: "flex",
    height: "100vh",
    width: "100%",
    flexFlow: "column wrap",
    justifyContent: "center",
  },
  leftDiv: {
    width: "40%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "70% 30%",
    alignItems: "center",
    justifyItems: "center",
  },
  aliadaImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    gridArea: "1/1/3/2"
  },
  preguntas: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  conBaseEn: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  radioOn: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  radioOff: {
    opacity: '0.38',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)'
  }

});

function NewForm(props) {
  const classes = useStyles();

  const [address, setAddress] = useState();
  const [lon, setLon] = useState(0);
  const [lat, setLat] = useState(0);

  function InputAddress() {
    function onSuggestSelectHandler(suggest) {
      if (suggest.formatted_address) {
        setAddress(suggest.formatted_address);
        setLon(suggest.geometry.location.lng());
        setLat(suggest.geometry.location.lat());
      }
    }

    return (
      <div className={classes.autocomplete}>
        <GoogleAutocomplete
          onClick={() => setAddress()}
          style={{ width: "83%", minHeight: 48 }}
          onPlaceSelected={onSuggestSelectHandler}
          types={[]}
          componentRestrictions={{ country: "MX" }}
          placeholder={"Escribe tu dirección"}
          value={address}
        />
        <PlaceIcon style={{ color: "grey", fontSize: 47 }} />
      </div>
    );
  }

  const [soloUnaVez, setSoloUnaVez] = useState();
  const [semanal, setSemanal]       = useState(true);
  const [urgente, setUrgente]       = useState();

  function SelectFrecServ() {
    function onChangeHandler(event) {
      switch (event.target.value) {
        case "1":
          setSoloUnaVez(true);
          setSemanal(false);
          setUrgente(false);
          break;
        case "2":
          setSoloUnaVez(false);
          setSemanal(true);
          setUrgente(false);
          break;
        case "3":
          setSoloUnaVez(false);
          setSemanal(false);
          setUrgente(true);
          break;
        default:
      }
    }

    const radio = <Radio color="primary" className={classes.radios} />;
    const labelPlacement = "end";

    return (
      <div style={{width: '70%'}}>
        <RadioGroup
          onChange={onChangeHandler}
          row
          className={classes.RadioGroup}
        >
          <FormControlLabel
            value={1}
            control={radio}
            className={soloUnaVez ? classes.radioOn : classes.radioOff}
            label="Sólo una vez"
            labelPlacement={labelPlacement}
            checked={soloUnaVez}
          />

          <FormControlLabel
            value={2}
            control={radio}
            className={semanal ? classes.radioOn : classes.radioOff}
            label="Semanal"
            labelPlacement={labelPlacement}
            checked={semanal}
          />

          <FormControlLabel
            value={3}
            control={radio}
            className={urgente ? classes.radioOn : classes.radioOff}
            label="Urgente"
            labelPlacement={labelPlacement}
            checked={urgente}
          />
        </RadioGroup>
      </div>
    );
  }

  const [hours, setHours] = useState(3);

  function SelectHours() {
    function onChangeHandler(event) {
      setHours(event.target.value);
    }

    return (
      <Select value={hours} variant="outlined" style={{height: '48px'}} onChange={onChangeHandler}>
        <MenuItem value={3}>3 Horas</MenuItem>
        <MenuItem value={4}>4 Horas</MenuItem>
        <MenuItem value={5}>5 Horas</MenuItem>
        <MenuItem value={6}>6 Horas</MenuItem>
        <MenuItem value={7}>7 Horas</MenuItem>
        <MenuItem value={8}>8 Horas</MenuItem>
      </Select>
    );
  }

  const [pets, setPets] = useState(false);

  function SelectPets() {
    const yes = "Sí";
    const no = "No";

    function onChangeHandler(_) {
      setPets(!pets);
    }

    return (
      <Select value={pets ? yes : no} style={{height: '48px'}} variant="outlined" onChange={onChangeHandler}>
        <MenuItem value={no}>{no}</MenuItem>
        <MenuItem value={yes}>{yes}</MenuItem>
      </Select>
    );
  }

  const [lavadoDeRopa, setLavadoDeRopa] = useState(false);
  const [planchado, setPlanchado] = useState(false);
  const [cocinar, setCocinar] = useState(false);

  function SelectServAdic() {
    function onChangeHandler(event) {
      switch (event.target.innerText) {
        case "Lavado de ropa":
          setLavadoDeRopa(!lavadoDeRopa);
          break;
        case "Planchado":
          setPlanchado(!planchado);
          break;
        case "Cocinar":
          setCocinar(!cocinar);
          break;
        default:
      }
    }

    return (
      <div className={classes.skillChips}>
        <Chip
          label="Lavado de ropa"
          style={{borderRadius: 6, minHeight: 48}}
          onClick={onChangeHandler}
          variant="outlined"
          color={lavadoDeRopa ? "primary" : "default"}
        />
        <Chip
          label="Planchado"
          style={{borderRadius: 6, minHeight: 48}}
          onClick={onChangeHandler}
          variant="outlined"
          color={planchado ? "primary" : "default"}
        />
        <Chip
          label="Cocinar"
          style={{borderRadius: 6, minHeight: 48}}
          onClick={onChangeHandler}
          variant="outlined"
          color={cocinar ? "primary" : "default"}
        />
      </div>
    );
  }

  const date = new Date();
  const datetime = moment.tz(date, TIMEZONE);
  const day = datetime.day();
  const hour = datetime.hour();
  const saturday = 6;
  const sunday = 0;
  const urgentServiceOutOfTime =
    day === saturday
      ? !(8 <= hour && hour < 15)
      : day === sunday
      ? !(8 <= hour && hour < 14)
      : !(8 <= hour && hour < 17);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(
      !(
        address &&
        lon &&
        lat &&
        (soloUnaVez || semanal || urgente) &&
        !(urgentServiceOutOfTime && urgente)
      )
    );
  }, [address, lon, lat, soloUnaVez, semanal, urgente, urgentServiceOutOfTime]);

  const [urlAliadas, setUrlAliadas] = useState();

  const { userId } = qs.decode(props.location.search);

  useEffect(() => {
    const url = "/s/aliadas?";
    const neighborhood = `neighborhood=${address}&`;
    const coordinates = `latitude=${lat}&longitude=${lon}&`;
    const serviceType = soloUnaVez ? "serviceType=one-time&"
                                     : semanal
                                   ? "serviceType=recurrent&"
                                     : urgente
                                   ? "serviceType=urgent&"
                                     : "";
    const serviceHours = `serviceHours=${hours}&`;
    const preferences = pets ? "preferences=pets&" : "";
    const skills =
      `skills[]=cleaning&` +
      (lavadoDeRopa ? "skills[]=laundry&" : "") +
      (planchado ? "skills[]=ironing&" : "") +
      (cocinar ? "skills[]=cooking&" : "");
    const userIdParam = userId ? `userId=${userId}` : ''

    setUrlAliadas(
      url          +
      neighborhood +
      coordinates  +
      serviceType  +
      serviceHours +
      preferences  +
      skills       +
      userIdParam
    );
  }, [
    address,
    lat,
    lon,
    soloUnaVez,
    semanal,
    urgente,
    hours,
    pets,
    lavadoDeRopa,
    planchado,
    cocinar,
    userId
  ]);

  function SearchButton() {
    function trackSearch(){
      ReactGA.event({
        category: 'Botón',
        action: 'Onboarding - Buscar aliadas disponibles',
      })
    }

    return (
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        disabled={disabled}
        href={urlAliadas}
        onClick={trackSearch}
        style={{
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: 500,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.14,
          letterSpacing: "1.25px",
          minHeight: 48,
          textAlign: "center"
        }}
      >
        BUSCAR ALIADAS DISPONIBLES
      </Button>
    );
  }

  function UrgentServiceAlert() {
    return (
      <Alert
        className={classes.alert}
        severity="warning"
        icon={<WarningIcon style={{ fontSize: "xxx-large" }} />}
        color="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        <p>
          ¡Lo sentimos! Es muy tarde para solicitar un servicio urgente, por
          favor regresa mañana a partir de las 8 am.
        </p>
      </Alert>
    );
  }

  const [open, setOpen] = useState();

  useEffect(() => {
    setOpen(urgentServiceOutOfTime && urgente);
  }, [urgentServiceOutOfTime, urgente]);

  useEffect(() => {
    document.title = 'Aliada - Preferencias del Servicio'
  }, []);

  return (
    <div>
      <Collapse in={open} style={{position: 'fixed',top: '10%', width: '90%'}}>
        <UrgentServiceAlert />
      </Collapse>
      <div className={classes.mainDiv}>
        <Hidden smDown>
          <div className={classes.leftDiv}>
            <img
              alt="closed"
              className={classes.aliadaImg}
              src={Aliada}
            />
            <div style={{ gridArea: "2/1/3/2", width: "70%" }}>
              <h2 className={classes.aliadaText}>
                Susana, Aliada desde hace 5 años
              </h2>
              <p>
                “Susana es súper detallista y tiene mucha disposición. La
                recomiendo muchísimo”.
                <br />
                ─Blanca Contreras
              </p>
            </div>
          </div>
        </Hidden>
        <div style={{padding: "7% 5% 5% 5%", background: '#fafafa' }}>
          <h1>Preferencias del servicio</h1>
          <p className={classes.conBaseEn}>
            Con base en tus preferencias, te mostraremos Aliadas disponibles.
          </p>

          <p className={classes.preguntas}>¿Cuál es tu dirección?</p>
          <InputAddress />

          <p className={classes.preguntas}>¿Cuál es la frecuencia de tu servicio?</p>
          <SelectFrecServ />

          <p className={classes.preguntas}>¿Cuánto tiempo?</p>
          <SelectHours />

          <p className={classes.preguntas}>¿Tienes mascotas?</p>
          <SelectPets />

          <p className={classes.preguntas}>Servicios adicionales</p>
          <SelectServAdic />

          <div  style={{ marginTop: "30px" }}>
            <SearchButton/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewForm;
