import qs from "qs";

const querystring = {
  encode: (params) => {
    const options = {
      encode: false,
      arrayFormat: "brackets",
    };

    return qs.stringify(params, options);
  },
  decode: (params) => {
    const options = {
      ignoreQueryPrefix: true,
    };

    return qs.parse(params, options);
  },
};

export default querystring;
