import axios             from "axios";
import qs                from "../../utils/querystring";
import { baseUrl }       from "../../utils/urls";
import { serviceTypeId } from "../../utils/serviceType";

export async function fetchCalendar(params) {
  try{
    const url      = encodeURL(params)
    const response = await axios.get(url)

    return response.data

  } catch(e) {
    return null
  }
}

function encodeURL(params) {
  const {
    date,
    latitude,
    longitude,
    serviceHours,
    preferences,
    serviceType,
    skills,
    userId,
  } = params;

  const querystring = qs.encode({
    coordinates: {
      latitude:  latitude,
      longitude: longitude,
    },
    date:            date.format("YYYY-MM-DD"),
    estimated_hours: serviceHours,
    preferences:     preferences,
    service_type_id: serviceTypeId(serviceType),
    skills:          skills,
    userId:          userId,
  });

  return `${baseUrl()}/v1/calendar?${querystring}`
};
