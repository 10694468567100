// service type slugs
export const RECURRENT_SERVICE_TYPE = 'recurrent';
export const ONE_TIME_SERVICE_TYPE  = 'one-time';
export const URGENT_SERVICE_TYPE    = 'urgent';

// service type ids
export const RECURRENT_SERVICE_TYPE_ID = 1;
export const ONE_TIME_SERVICE_TYPE_ID  = 2;

// urgent service type traits
export const URGENT_SERVICE_ALIADA_ID = 4034;

export const MAX_SERVICE_HOURS = 14;
export const MIN_SERVICE_HOURS = 3;

export const TIMEZONE = 'America/Mexico_City'

export const START_HOUR_SERVICES               = 7
export const END_HOUR_URGENT_SERVICES          = 17
export const END_HOUR_URGENT_SERVICES_SATURDAY = 15
export const END_HOUR_URGENT_SERVICES_SUNDAY   = 14

export const ANALYTICS_UA = 'UA-163365925-1'