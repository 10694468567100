import React from "react";

import HourPickerSlot               from "./HourPickerSlot";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: '138px',
    margin: "10px auto",

    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
      height: '102px',
    },
  },
}));

export default function HourPicker(props) {
  const classes                   = useStyles();
  const { hour, slots, onChange } = props;

  const entries = slots.map((slot, index) => {
    const active   = hour === slot.hour;
    const disabled = !slot.available;

    return (
      <HourPickerSlot
        key={index}
        hour={slot.hour}
        onClick={onChange}
        active={active}
        disabled={disabled}
        discount={slot.discount}
      />
    );
  });

  return (
    <div className={classes.root}>
      { entries }
    </div>
  );
}
