import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import HourPicker from "../../components/HourPicker";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import PersonIcon from "@material-ui/icons/Person";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import WeekDatePicker from "../../components/WeekDatePicker";
import axios from "axios";
import moment from "moment";
import qs from "../../utils/querystring";
import { Amplitude } from "@amplitude/react-amplitude";
import { baseUrl } from "../../utils/urls";
import { bookingUrl } from "../../utils/urls";
import { makeStyles } from "@material-ui/core/styles";
import { serviceTypeId } from "../../utils/serviceType";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';

import { ANALYTICS_UA } from "../../utils/constants";

import {
  Broom,
  Calendar,
  Hanger,
  Noodles,
  OfficeBuilding,
  Paw,
  WashingMachine
} from "mdi-material-ui";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  avatar: {
    width: 120,
    height: 120,
    margin: "16px auto"
  },
  header: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    marginTop: "0px"
  },
  titleOverline: {
    lineHeight: 1.5
  },
  stats: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "15px",
    marginBottom: "-10px"
  },
  statsColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  section: {
    margin: "25px auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  sectionTitle: {
    marginBottom: "8px"
  },
  meter: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  progressBar: {
    width: "50%"
  },
  comment: {
    margin: "13px 15px"
  },
  divider: {
    margin: "3px auto",
    width: "90%"
  },
  icon: {
    verticalAlign: "text-top",
    height: "13px",
    width: "13px",
    marginRight: "3px"
  },
  rater: {
    marginTop: "8px"
  },
  skills: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  chip: {
    margin: "6px"
  },
  chipIcon: {
    marginLeft: "8px",
    height: "0.7em",
    width: "0.7em"
  },
  availability: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  datePicker: {
    marginTop: "12px"
  },
  hourPicker: {
    width: "100%",
    marginTop: "8px"
  },
  loader: {
    marginTop: "45px"
  },
  button: {
    margin: "15px auto"
  }
}));

export default function AliadaProfile(props) {
  const classes = useStyles();

  const { aliadaId } = useParams();
  const [aliada, setAliada] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  ReactGA.initialize(ANALYTICS_UA, { testMode: process.env.NODE_ENV === 'test' });
  ReactGA.pageview(`onboarding/aliadas/${aliadaId}`);

  const { date, serviceType, serviceHours, userId } = qs.decode(
    props.location.search
  );

  useEffect(() => {
    async function fetchAliada() {
      const url = `${baseUrl()}/v1/aliadas/${aliadaId}`;
      const response = await axios.get(url);

      setAliada(response.data);
    }

    fetchAliada();
  }, [aliadaId]);

  if (aliada == null) {
    return null;
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Header aliada={aliada} />

      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, tab) => setCurrentTab(tab)}
        variant="fullWidth"
      >
        <Tab label="Perfil" />
        <Tab label="Disponibilidad" />
      </Tabs>

      {currentTab === 0 && <Profile aliada={aliada} />}
      {currentTab === 1 && (
        <Availability
          aliada={aliada}
          date={moment(date)}
          serviceType={serviceType}
          serviceHours={parseInt(serviceHours)}
          userId={userId}
        />
      )}
    </Container>
  );
}

function Header(props) {
  const classes = useStyles();

  const { aliada } = props;

  const isSuperAliada = () => aliada.superaliada;
  const isNewAliada = () => aliada.ratings.length === 0;

  return (
    <div>
      <Avatar
        alt={aliada.first_name}
        className={classes.avatar}
        src={aliada.profile_picture.xhdpi}
      />

      <div className={classes.header}>
        {isSuperAliada() && (
          <Typography
            className={classes.titleOverline}
            align="center"
            color="primary"
            variant="overline"
          >
            SUPERALIADA
          </Typography>
        )}

        {isNewAliada() && (
          <Typography
            className={classes.titleOverline}
            align="center"
            color="primary"
            variant="overline"
            style={{ color: "#FF8C42" }}
          >
            ALIADA NUEVA
          </Typography>
        )}

        <Typography className={classes.title} align="center" variant="h4">
          {aliada.first_name.split(" ")[0]}
        </Typography>
      </div>
    </div>
  );
}

function Profile(props) {
  const classes = useStyles();

  const { aliada } = props;

  const ratingsCount = Object.values(aliada.stars).reduce((a, b) => a + b, 0);

  const ratings = (() => {
    const percentage = stars => {
      if (stars === 0 || ratingsCount === 0) {
        return 0;
      }

      return Math.floor((stars / ratingsCount) * 100);
    };

    return {
      fiveStars: percentage(aliada.stars.five),
      fourStars: percentage(aliada.stars.four),
      threeStars: percentage(aliada.stars.three),
      twoStars: percentage(aliada.stars.two),
      oneStar: percentage(aliada.stars.one)
    };
  })();

  return (
    <div>
      <div className={classes.stats}>
        <div className={classes.statsColumn}>
          <Typography variant="overline" className={classes.stats.header}>
            Servicios
          </Typography>

          <Typography
            className={classes.stats.header}
            color="textSecondary"
            variant="overline"
          >
            {aliada.finished_services_count}
          </Typography>
        </div>

        <div className={classes.statsColumn}>
          <Typography variant="overline" className={classes.stats.header}>
            Tarifa por hora
          </Typography>

          <Typography
            className={classes.stats.header}
            color="textSecondary"
            variant="overline"
          >
            $ {aliada.price_per_hour}
          </Typography>
        </div>

        <div className={classes.statsColumn}>
          <Typography variant="overline" className={classes.stats.header}>
            Calificacion
          </Typography>

          <Typography
            className={classes.stats.header}
            color="textSecondary"
            variant="overline"
          >
            {aliada.average_rating.toFixed(1)} ★
          </Typography>
        </div>
      </div>

      <div className={classes.section}>
        <Typography
          variant="overline"
          align="center"
          className={classes.sectionTitle}
        >
          Habilidades
        </Typography>

        <div>
          <div className={classes.skills}>
            {[...aliada.skills, ...aliada.preferences].map((skill, index) => (
              <Skill key={index} slug={skill.slug} label={skill.name} />
            ))}
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <Typography
          variant="overline"
          align="center"
          className={classes.sectionTitle}
        >
          Servicios Calificados
        </Typography>

        <div>
          <div className={classes.meter}>
            <Typography color="textSecondary" variant="caption">
              5 ★
            </Typography>

            <LinearProgress
              variant="determinate"
              value={ratings.fiveStars}
              className={classes.progressBar}
            />

            <Typography color="textSecondary" variant="caption">
              {aliada.stars.five || 0} servicios
            </Typography>
          </div>

          <div className={classes.meter}>
            <Typography color="textSecondary" variant="caption">
              4 ★
            </Typography>

            <LinearProgress
              variant="determinate"
              value={ratings.fourStars}
              className={classes.progressBar}
            />

            <Typography color="textSecondary" variant="caption">
              {aliada.stars.four || 0} servicios
            </Typography>
          </div>

          <div className={classes.meter}>
            <Typography color="textSecondary" variant="caption">
              3 ★
            </Typography>

            <LinearProgress
              variant="determinate"
              value={ratings.threeStars}
              className={classes.progressBar}
            />

            <Typography color="textSecondary" variant="caption">
              {aliada.stars.three || 0} servicios
            </Typography>
          </div>

          <div className={classes.meter}>
            <Typography color="textSecondary" variant="caption">
              2 ★
            </Typography>

            <LinearProgress
              variant="determinate"
              value={ratings.twoStars}
              className={classes.progressBar}
            />

            <Typography color="textSecondary" variant="caption">
              {aliada.stars.two || 0} servicios
            </Typography>
          </div>

          <div className={classes.meter}>
            <Typography color="textSecondary" variant="caption">
              1 ★
            </Typography>

            <LinearProgress
              variant="determinate"
              value={ratings.oneStar}
              className={classes.progressBar}
            />

            <Typography color="textSecondary" variant="caption">
              {aliada.stars.one || 0} servicios
            </Typography>
          </div>
        </div>
      </div>

      <div className={classes.section}>
        <Typography
          variant="overline"
          align="center"
          className={classes.sectionTitle}
        >
          Comentarios
        </Typography>

        <div>
          {aliada.ratings.map((rating, index) => (
            <div key={index}>
              <UserRating
                comment={rating.comment}
                rater={rating.user.first_name}
                ratedAt={rating.created_at}
              />
              <Divider light={true} className={classes.divider} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Skill(props) {
  const classes = useStyles();

  const { label, slug } = props;

  const icons = {
    cleaning: <Broom className={classes.chipIcon} />,
    laundry: <WashingMachine className={classes.chipIcon} />,
    ironing: <Hanger className={classes.chipIcon} />,
    cooking: <Noodles className={classes.chipIcon} />,
    pets: <Paw className={classes.chipIcon} />,
    offices: <OfficeBuilding className={classes.chipIcon} />,
    recurrences: <Calendar className={classes.chipIcon} />
  };

  return (
    <Chip
      size="small"
      label={label}
      icon={icons[slug]}
      className={classes.chip}
    />
  );
}

function UserRating(props) {
  const classes = useStyles();

  const { comment } = props;

  const rater = props.rater.split(" ")[0];
  const ratedAt = moment(props.ratedAt).fromNow();

  return (
    <div className={classes.comment}>
      <Typography variant="body2">”{comment}”</Typography>

      <div className={classes.rater}>
        <PersonIcon className={classes.icon} />
        <Typography variant="caption">
          {rater}, {ratedAt}
        </Typography>
      </div>
    </div>
  );
}

function Availability(props) {
  const classes = useStyles();

  const { aliada, serviceType, serviceHours, userId } = props;

  const [date, setDate] = useState(props.date);
  const [hour, setHour] = useState(null);
  const [timeslots, setTimeslots] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    async function fetchAliadaCalendar() {
      const url = `${baseUrl()}/v1/aliadas/${aliada.id}/calendar`;
      const response = await axios.get(url, {
        params: {
          estimated_hours: serviceHours,
          date: date.toISOString(),
          service_type_id: serviceTypeId(serviceType)
        }
      });
      const data = response.data.map(obj => ({
        hour: moment(obj.time).hours(),
        available: obj.available
      }));

      const defaultHour = data.find(timeslot => timeslot.available);

      setHour(defaultHour ? defaultHour.hour : null);
      setTimeslots(data);
      setIsFetching(false);
    }

    setIsFetching(true);

    fetchAliadaCalendar();
  }, [aliada.id, date, serviceType, serviceHours]);

  if (aliada == null) {
    return null;
  }

  const bookingParams = {
    date: date,
    serviceHours: serviceHours,
    serviceType: serviceType,
    hour: hour,
    id: aliada.id,
    userId: userId
  };

  return (
    <Amplitude eventProperties={{ section: "profile" }}>
      {({ instrument }) => (
        <div className={classes.availability}>
          <div className={classes.datePicker}>
            <WeekDatePicker selectedDate={date} onChange={setDate} />
          </div>

          {isFetching && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}

          {!isFetching && (
            <div className={classes.hourPicker}>
              <HourPicker hour={hour} onChange={setHour} slots={timeslots} />
            </div>
          )}

          {!isFetching && hour && (
            <Button
              className={classes.button}
              color="primary"
              component={Link}
              href={bookingUrl(bookingParams)}
              variant="contained"
              style={{ color: "#fff" }}
              onClick={instrument("click-on-book-service")}
            >
              Reservar con {aliada.first_name.split(" ")[0]}
            </Button>
          )}
        </div>
      )}
    </Amplitude>
  );
}
