import React from 'react';

import TextField                  from '@material-ui/core/TextField';
import Autocomplete               from '@material-ui/lab/Autocomplete';
import CircularProgress           from '@material-ui/core/CircularProgress';
import { makeStyles }             from "@material-ui/core/styles";
import Avatar                     from "@material-ui/core/Avatar";
import Typography                 from "@material-ui/core/Typography";
import { Link as RouterLink }     from "react-router-dom";
import { aliadaProfileUrl }       from "../../utils/urls";
import moment                     from "moment";
import SearchIcon                 from '@material-ui/icons/Search';
import { bookingFirstDate }       from "../../utils/calendar";
import { fetchAliadasByLocation } from "../../utils/api/AliadasByLocation";

const useStyles = makeStyles({
  root: {
    alignItems:     "center",
    display:        "flex",
    flexDirection:  "row",
    justifyContent: "flex-end",
    margin:         "10px 0px",
    height:         "80px",
  },
  column: {
    display:       "flex",
    flexDirection: "column",
    margin:        "8px 10px",
  },
  avatar: {
    width:         95,
    height:        95,
  },
  averageRating: {
    display:        "flex",
    alignItems:     "center",
    justifyContent: "flex-start",
  },
  overline: {
    lineHeight: 1.0,
  },
  text: {
    margin: "auto 0px",
  },
  label: {
    display: "flex",
    direction: "row",
  },
  text1: {
    marginLeft: "5px"
  }
});

export default function AliadaAutocomplete(props) {
  const classes = useStyles();

  const {
    userId,
    serviceHours,
    serviceType,
    latitude,
    longitude,
  } = props;

  const bookingParams = {
    serviceHours: serviceHours,
    serviceType: serviceType,
    userId: userId,
    hour: 10,
    date: bookingFirstDate(),
    latitude: latitude,
    longitude: longitude,
    time: 10
  };

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    (async () => {
      const response = await fetchAliadasByLocation(latitude, longitude, userId, serviceType);
      if (response !== undefined){
        const aliadas = response.map(aliada => {
          const groupBy = aliada.last_service === undefined ? "Otras aliadas" : "Mis últimas aliadas"
          return {
            groupBy: groupBy,
            ...aliada,
          };
        });
        setOptions(aliadas);
      } else {
        setOptions([]);
      }
    })();
  }, [latitude, longitude, userId, serviceType]);

  return (
    <Autocomplete
      id="aliada-search-autocomplete"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.first_name}
      groupBy={option => option.groupBy}
      options={options.sort((a,b) => -b.groupBy.localeCompare(a.groupBy))}
      loading={loading}
      renderInput={params => (
        <TextField
          style={{marginTop: '13%'}}
          {...params}
          label= {
            <div className={classes.label}>
              <SearchIcon />
              <Typography className={classes.text1}>
                Buscar a una aliada
              </Typography>
            </div>
          }
          fullWidth
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(option) => (
        <RouterLink
          to={aliadaProfileUrl({id: option.id, ...bookingParams})}
          style={{ textDecoration: 'none', color: "black" }}
        >
          <div className={classes.root}>
            <Avatar alt={option.first_name} src={option.profile_picture} className={classes.avatar} />

            <div className={classes.column}>
              <Typography variant="h6" className={classes.text}>
                {option.first_name}
              </Typography>

              <div className={classes.averageRating}>
                <Typography variant="caption" className={classes.text}>
                  {Number(option.average_rating).toFixed(1)} ★
                </Typography>
              </div>

              {option.last_service &&
                <Typography className={classes.text}>
                  Último servicio: {moment(option.last_service).fromNow()}
                </Typography>
              }
            </div>
          </div>
        </RouterLink>
      )}
    />
  );
}
