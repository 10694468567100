import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography     from "@material-ui/core/Typography";
import moment         from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "14px 0px",
    width:  "280px",
  },
  comment: {
    [theme.breakpoints.up('sm')]: {
      height:   "60px",
      overflow: "auto",
    },
  },
  caption: {
    marginTop: "8px",
  },
}));

export default function UserRating(props) {
  const classes = useStyles();

  const { comment } = props;
  const rater       = props.rater.split(" ")[0];
  const ratedAt     = moment(props.ratedAt).fromNow();

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.comment}>
        ”{comment}”
      </Typography>

      <div className={classes.caption}>
        <Typography variant="caption">
          {rater}, {ratedAt}
        </Typography>
      </div>
    </div>
  );
};
