import React from "react";

import AliadaCardHeader from "./AliadaCardHeader";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import UserRating from "./UserRating";
import UserRatingPlaceholder from "./UserRatingPlaceholder";
import { Amplitude } from "@amplitude/react-amplitude";
import { bookingUrl } from "../../utils/urls";
import { aliadaProfileUrl } from "../../utils/urls";
import { makeStyles } from "@material-ui/core/styles";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    margin: "20px 20px"
  },
  button: {
    display: "flex",
    maxWidth: "280px",
    marginTop: "5px",
    marginBottom: "5px"
  }
});

export default function AliadaCard(props) {
  const classes = useStyles();

  const {
    id,
    index,
    firstName,
    profilePicture,
    serviceCost,
    serviceOriginalCost,
    superaliada,
    averageRating,
    finishedServices,
    userRatings,
    serviceType,
    serviceHours,
    hour,
    date,
    userId
  } = props;

  const bookingParams = {
    date: date,
    serviceHours: serviceHours,
    serviceType: serviceType,
    hour: hour,
    id: id,
    userId: userId
  };

  const newAliada = userRatings.length === 0;
  const lastRating = userRatings[0];

  const LinkToProfile = React.forwardRef((props, ref) => (
    <RouterLink
      to={aliadaProfileUrl(bookingParams)}
      innerRef={ref}
      {...props}
    />
  ));

  return (
    <Amplitude eventProperties={{ index: index, section: "list" }}>
      {({ instrument }) => (
        <div className={classes.root}>
          <AliadaCardHeader
            firstName={firstName}
            profilePicture={profilePicture}
            averageRating={averageRating}
            serviceCost={serviceCost}
            serviceOriginalCost={serviceOriginalCost}
            finishedServices={finishedServices}
            superaliada={superaliada}
            newAliada={newAliada}
          />

          <div className={classes.userRating}>
            {lastRating != null ? (
              <UserRating
                comment={lastRating.comment}
                rater={lastRating.user.first_name}
                ratedAt={lastRating.created_at}
              />
            ) : (
              <UserRatingPlaceholder firstName={firstName} />
            )}
          </div>

          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            component={LinkToProfile}
          >
            Ver perfil de {firstName.split(" ")[0]}
          </Button>

          <Button
            className={classes.button}
            color="primary"
            component={Link}
            href={bookingUrl(bookingParams)}
            variant="contained"
            onClick={instrument("click-on-book-service")}
            style={{ color: "#fff" }}
          >
            Reservar con {firstName.split(" ")[0]}
          </Button>
        </div>
      )}
    </Amplitude>
  );
}
