import React from "react";

import MUIAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/images/logo.png";
import { baseUrl } from "../../utils/urls";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  appBar: {
    backgroundColor: "transparent",
    zIndex: 2,
    position: "absolute"
  }
}));

function AppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MUIAppBar
        className={classes.appBar}
        position="static"
        color="default"
        elevation={0}
        maxwidth="sm"
      >
        <Toolbar>
          <a href={baseUrl()}>
            <img src={logo} alt="aliada" height="25" />
          </a>
        </Toolbar>
      </MUIAppBar>
    </div>
  );
}

export default AppBar;
