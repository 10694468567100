import React from 'react';

import MaaxBold           from '../../utils/fonts/MaaxBold';
import MaaxRegular        from '../../utils/fonts/MaaxRegular';
import blue               from '../../utils/colors/blue';
import white              from '../../utils/colors/white';
import { ThemeProvider }  from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const headers = {
  fontWeight: 600,
  marginTop: '18px',
  marginBottom: '18px',
};

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Maax',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      ...headers,
    },
    h2: {
      ...headers,
    },
    h3: {
      ...headers,
    },
    h4: {
      ...headers,
    },
    h5: {
      ...headers,
    },
    h6: {
      ...headers,
    },
    subtitle1: {
      fontWeight: 600,
    },
    subtitle12: {
      fontWeight: 600,
    },
    caption: {
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 600,
    },
  },
  palette: {
    primary: blue,
    secondary: white,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          MaaxRegular,
          MaaxBold,
        ],
      },
    },
  },
});

function Theme(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  );
}

export default Theme;
