import React from "react";

import AliadaProfile from "../../pages/AliadaProfile/AliadaProfile";
import AvailableAliadasForBooking from "../../pages/AvailableAliadasForBooking";
import NewForm from "../../components/NewForm";

import { BrowserRouter as Router, Route } from "react-router-dom";

export default function AppRouter() {

  return (
    <Router>
      <Route exact path="/" component={NewForm} />
      <Route path="/s/aliadas" component={AvailableAliadasForBooking} />
      <Route path="/aliadas/:aliadaId" component={AliadaProfile} />
    </Router>
  );
}
