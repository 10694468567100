import {
  ONE_TIME_SERVICE_TYPE,
  ONE_TIME_SERVICE_TYPE_ID,
  RECURRENT_SERVICE_TYPE,
  RECURRENT_SERVICE_TYPE_ID,
  URGENT_SERVICE_TYPE,
} from './constants.js';

export const isOneTime   = (slug) => slug === ONE_TIME_SERVICE_TYPE;
export const isRecurrent = (slug) => slug === RECURRENT_SERVICE_TYPE;
export const isUrgent    = (slug) => slug === URGENT_SERVICE_TYPE;

export const serviceTypeId = (slug) => {
  switch (slug) {
    case RECURRENT_SERVICE_TYPE:
      return RECURRENT_SERVICE_TYPE_ID;
    case ONE_TIME_SERVICE_TYPE:
      return ONE_TIME_SERVICE_TYPE_ID;
    case URGENT_SERVICE_TYPE:
      return ONE_TIME_SERVICE_TYPE_ID;
    default:
      return null;
  }
};
