import React from "react";

import Typography            from "@material-ui/core/Typography";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List                  from '@material-ui/core/List';
import { makeStyles }        from "@material-ui/core/styles";
import ListItem              from '@material-ui/core/ListItem';
import ListItemIcon          from '@material-ui/core/ListItemIcon';
import ListItemText          from '@material-ui/core/ListItemText';
import closed                from '../../assets/images/closed.png'

const styles = makeStyles({
  root:{},
  loader: {
    margin: "20px auto",
    textAlign: "center"
  },
  button: {
    display: 'flex',
    width: '80%',
    margin: 'auto',
    textAlign: "center",
    borderRadius: '32px',
  },
  bullets: {
    minWidth: '35px'
  },
  listItem:{
    paddingTop:0
  },
  listTitle:{
    marginBottom:0
  }
});

export default function urgentServiceOutOfTime() {
  const classes = styles();

  return (
    <div>

      <div align="center"><img height="300" alt="closed" src={closed}/></div>
      <Typography variant='h6' align="center">
        Lo sentimos, regresa mañana.
      </Typography>

      <Typography variant='h6' align="left" className={classes.listTitle}>
        PUNTOS IMPORTANTES
      </Typography>

      <List component="nav" aria-label="main mailbox folders">
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon >
          <ListItemText primary="lunes a viernes de 7 am - 5 pm" />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="sábado de 7 am - 3 pm" />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="domingo de 7 am - 2 pm" />
        </ListItem>
      </List>
    </div>
  )
}
