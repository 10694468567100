import React from "react";

import classNames                   from "classnames/bind";
import Button                       from "@material-ui/core/Button";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { fade }                     from "@material-ui/core/styles/colorManipulator";
import MonetizationOnIcon           from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    padding: '2px',
  },
  active: {
    backgroundColor: fade(
      theme.palette.primary.main,
      theme.palette.action.hoverOpacity
    ),
  }
}));

export default function HourPickerSlot(props) {
  const classes = useStyles();

  const { active, disabled, hour, onClick, discount } = props;

  const formattedHour = () => {
    const meridiem = (hour < 12) ? 'AM' : 'PM';

    return `${hour.toString().padStart(2, "0")}:00 ${meridiem}`;
  };

  const buttonClasses = classNames(active ? classes.active : null);

  const handleOnClick = () => {
    if (active || disabled) {
      return;
    }

    onClick(hour);
  };

  return (
    <div className={classes.root}>
      <Button
        className={buttonClasses}
        color={ active ? "primary" : "default"}
        disabled={ disabled }
        onClick={ handleOnClick }
        size='small'
        fullWidth={true}
        style={discount ? {alignItems: 'normal'} : {alignItems: 'inherit'}}
      >
        { formattedHour() }
        <MonetizationOnIcon style={discount ? { fontSize: 13 } : { fontSize: 0 }}
                            color={"primary"}
        ></MonetizationOnIcon>
      </Button>
    </div>
  );
}
