import axios             from "axios";
import qs                from "../../utils/querystring";
import { baseUrl }       from "../../utils/urls";
import { serviceTypeId } from "../serviceType";

export async function fetchAliadasByLocation(latitude,
                                             longitude,
                                             userId,
                                             serviceType) {
  const url      = encodeURL(latitude, longitude, userId, serviceType);
  const response = await axios.get(url);

  return response.data;
}

function encodeURL(latitude, longitude, userId, serviceType) {

  const querystring = qs.encode({
    longitude:       longitude,
    latitude:        latitude,
    user_id:         userId,
    service_type_id: serviceTypeId(serviceType)
  })

  return `${baseUrl()}/v1/aliadas/search?${querystring}`
};
