import React from 'react';

import Fab            from '@material-ui/core/Fab';
import Intercom       from 'react-intercom';
import ChatIcon       from '@material-ui/icons/Chat';
import { makeStyles } from '@material-ui/core/styles';

const intercomAppID = process.env.REACT_APP_INTERCOM_APP_ID;

const useStyles = makeStyles(theme => ({
  fab: {
    margin: 0,
    top: 'auto',
    right: '16px',
    left: 'auto',
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      bottom: '16px',
    },
    [theme.breakpoints.only('xs')]: {
      bottom: '68px',
    },
  },
  icon: {
    fontSize: '1.75rem',
  },
}));

function HelpFab(props) {
  const classes = useStyles();

  return (
    <div>
      <Fab
        className={classes.fab}
        color='primary'
        id='intercom-fab'
        href={`mailto:${intercomAppID}@intercom-mail.com`}
      >
        <ChatIcon className={classes.icon} />
      </Fab>

      <Intercom
        appID={intercomAppID}
        vertical_padding={75}
        custom_launcher_selector={'#intercom-fab'}
        hide_default_launcher={true}
      />
    </div>
  );
}

export default HelpFab;
