const white = {
  50:   '#f9f9f9',
  100:  '#f3f3f3',
  200:  '#eaeaea',
  300:  '#dadada',
  400:  '#b7b7b7',
  500:  '#979797',
  600:  '#6e6e6e',
  700:  '#5b5b5b',
  800:  '#3c3c3c',
  900:  '#1c1c1c',
  A100: '#82b1ff',
  A200: '#448aff',
  A400: '#2979ff',
  A700: '#2962ff',
};

export default white;
