import React from "react";

import classNames                   from "classnames/bind";
import Button                       from "@material-ui/core/Button";
import Typography                   from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { fade }                     from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  active: {
    backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
  }
}));

export default function CalendarEntry (props) {
  const classes = useStyles();

  const { active, date, disabled, onClick } = props;

  const handleOnClick = () => {
    if (active || disabled) {
      return;
    }

    onClick(date);
  };

  const buttonClasses = classNames(active ? classes.active : null);

  return (
    <div className={classes.root}>
      <Typography variant="overline">
        { date.format("dd") }
      </Typography>

      <Button
        className={ buttonClasses }
        color={ active ? "primary" : "default"}
        disabled={ disabled }
        onClick={ handleOnClick }
        size='small'
      >
        { date.format("DD") }
      </Button>
    </div>
  );
};
