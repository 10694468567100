import React from "react";

import AppBar from "../../components/AppBar";
import AppRouter from "../../components/AppRouter";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import HelpFab from "../../components/HelpFab";
import MomentUtils from "@date-io/moment";
import Theme from "../../components/Theme";
import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import "moment/locale/es";

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;

function App() {
  return (
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={AMPLITUDE_KEY}
    >
      <Theme>
        <CssBaseline />

        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="es"
          style={{ padding: 0 }}
        >
          <AppBar />

          <Container maxWidth="lg" style={{ padding: 0 }}>
            <AppRouter />
          </Container>

          <HelpFab />
        </MuiPickersUtilsProvider>
      </Theme>
    </AmplitudeProvider>
  );
}

export default App;
