import EOT   from './Maax-Bold.eot';
import SVG   from './Maax-Bold.svg';
import TTF   from './Maax-Bold.ttf';
import WOFF  from './Maax-Bold.woff';
import WOFF2 from './Maax-Bold.woff2';

const MaaxBold = {
  fontFamily: 'Maax',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `
    url('${EOT}?#iefix') format('embedded-opentype'),
    url('${WOFF2}') format('woff2'),
    url('${WOFF}') format('woff'),
    url('${TTF}') format('truetype'),
    url('${SVG}#svgFontName') format('svg')
  `,
};

export default MaaxBold;
