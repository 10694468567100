import React from "react";

import Typography     from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    margin: "14px 0px",
    width:  "280px",
  },
  comment: {
    minHeight: "60px",
  },
  caption: {
    marginTop: "8px",
  },
});

export default function UserRatingPlaceholder(props) {
  const classes = useStyles();

  const { firstName } = props;

  const comment = `${firstName} pasó por los más estrictos filtros para
    prestar sus servicios en la plataforma.`;

  return (
    <div className={classes.root}>
      <Typography variant="body2" className={classes.comment}>
        ”{comment}”
      </Typography>

      <div className={classes.caption}>
        <Typography variant="caption">
          Aliada ♥
        </Typography>
      </div>
    </div>
  );
};
