import EOT   from './Maax-Regular.eot';
import SVG   from './Maax-Regular.svg';
import TTF   from './Maax-Regular.ttf';
import WOFF  from './Maax-Regular.woff';
import WOFF2 from './Maax-Regular.woff2';

const MaaxRegular = {
  fontFamily: 'Maax',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    url('${EOT}?#iefix') format('embedded-opentype'),
    url('${WOFF2}') format('woff2'),
    url('${WOFF}') format('woff'),
    url('${TTF}') format('truetype'),
    url('${SVG}#svgFontName') format('svg')
  `,
};

export default MaaxRegular;
