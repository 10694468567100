import React, { useEffect } from "react";

import axios          from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography     from "@material-ui/core/Typography";
import { baseUrl, urgentServiceBookingUrl }    from "../../utils/urls";
import { makeStyles } from "@material-ui/core/styles";
import Button         from '@material-ui/core/Button';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = makeStyles({
  root:{},
  loader: {
    margin: "20px auto",
    textAlign: "center"
  },
  button: {
    display: 'flex',
    width: '80%',
    margin: 'auto',
    textAlign: "center",
    borderRadius: '32px',
  },
  bullets: {
    minWidth: '35px'
  },
  listItem:{
    paddingTop:0
  },
  listTitle:{
    marginBottom:0
  }
});

function UrgenteService(props) {
  const classes = styles();

  const { hours, userId } = props;
  const [ serviceCost, setServiceCost] = React.useState(null);
  const [ isFetching, setIsFetching ]  = React.useState(true);

  useEffect(() => {
    async function fetchServiceCost() {
      const url = `${baseUrl()}/v1/urgent_services/cost`;
      const response = await axios.get(url, {
        params: {
          service_hours: hours
        }
      });

      setServiceCost(response.data.service.cost);
      setIsFetching(false);
    }

    setIsFetching(true);
    fetchServiceCost();
  }, [hours]);

  return (
    <div>
      <Typography variant='h6' align="center">
        Calculamos el costo de tu servicio.
      </Typography>

    
      {isFetching && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      {!isFetching && (
        <Typography variant='h2' color='primary' align="center">
          $ {serviceCost}
        </Typography>
      )}

      <Typography variant='h6' align="left" className={classes.listTitle}>
        PUNTOS IMPORTANTES
      </Typography>

      <List component="nav" aria-label="main mailbox folders">
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon >
          <ListItemText primary="Buscaremos aliadas disponibles" />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Te contactaremos en 20 minutos para confirmar tu servicio." />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.bullets}>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Una vez que recibas confirmación, procesaremos tu pago." />
        </ListItem>
      </List>

      <Button
        className={classes.button}
        color='primary'
        variant='contained'
        href={urgentServiceBookingUrl(hours, userId)}
        size="large"
      >
        { 'RESERVAR' }
      </Button>
    </div>
  )
}

export default UrgenteService;